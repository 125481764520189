// 生产环境
export default {
    HOST: "",
    WS_HOST: "ws://" + location.host
}


// 测试环境
// export default {
//     HOST: "http://127.0.0.1:4050",
//     WS_HOST: "ws://127.0.0.1:4050"
// }